import React from "react"
import { Helmet } from "react-helmet"
import Header from "../../components/header"
import Footer from "../../components/footer"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import "../../styles/style.css"

const Integracion = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "cabecera-qualoom.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Especialistas en Integración Continua en Software | Qualoom</title>
        <meta name="title" content="Especialistas en Integración Continua en Software | Qualoom"/>
        <meta name="description" content="En Qualoom automatizamos tareas en Servicios DevOps para conseguir una Metodología ágil en el desarrollo de Software. ¡Consúltanos e Infórmate YA!"/>
        <html lang='es'/>        
        <link rel="alternate" href="https://www.qualoom.es/devops/integracion-continua/"/>
        <link rel="canonical" href="https://www.qualoom.es/devops/integracion-continua/"/>
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>
        
        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.qualoom.es/devops/integracion-continua/"/>
        <meta property="og:title" content="Especialistas en Integración Continua en Software | Qualoom"/>
        <meta property="og:description" content="En Qualoom automatizamos tareas en Servicios DevOps para conseguir una Metodología ágil en el desarrollo de Software. ¡Consúltanos e Infórmate YA!"/>
        <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3qEkBiWp6XWYzJYweuZVSK/00681335572a0fe04ef29bc8dba9096b/tw_cards_servicios.png"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>
        <meta property="fb:admins" content="338114870907726"/>
        
        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://www.qualoom.es/devops/integracion-continua/"/>
        <meta property="twitter:title" content="Especialistas en Integración Continua en Software | Qualoom"/>
        <meta property="twitter:description" content="En Qualoom automatizamos tareas en Servicios DevOps para conseguir una Metodología ágil en el desarrollo de Software. ¡Consúltanos e Infórmate YA!"/>
        <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3qEkBiWp6XWYzJYweuZVSK/00681335572a0fe04ef29bc8dba9096b/tw_cards_servicios.png"/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>

        <script type="application/ld+json">
        {`{"@type": "BreadcrumbsList", "@id":"https://www.qualoom.es/devops/integracion-continua/#breadcrumb",              
            "itemListElement":[
              {"@type":"ListItem", "position":"1", "name":"Inicio", "item": {"@id": "https://www.qualoom.es/", "name": "Inicio"}},
              {"@type":"ListItem", "position":"2", "name":"DevOps", "item": {"@id": "https://www.qualoom.es/devops/", "name": "DevOps"}},
              {"@type":"ListItem", "position":"3", "name":"Integración continua", "item": {"@id": "https://www.qualoom.es/devops/integracion-continua/", "name": "Integración continua"}}
            ]}`}
        </script>
      </Helmet>
      <BackgroundImage fluid={data.image.childImageSharp.fluid}>
        <div className="background-servicios">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>Desarrollo ágil con integración continua</h1>
              <p>La integración continua es la práctica ampliamente recomendada y aplicada en metodologías ágiles y DevOps, que incluye la integración regular y la prueba de los cambios de los productos construidos desde las primeras etapas del ciclo productivo.</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="services-main">
        <div className="services-main-content">
          <h2>¿Qué es la integración continua?</h2><hr></hr>
          <p>La integración continua es una práctica dentro de la metodología DevOps de ingeniería de software que implica hacer integraciones automáticas de un proyecto con cierta frecuencia para así poder detectar posibles fallos lo antes posible.</p><br/>
          <h3>Las ventajas de la integración continua</h3>
          <ul>
            <li>Los desarrolladores podrán detectar y resolver los problemas de integración de forma continua en entornos no productivos (staging y preproducción), de manera que se evitará el caos de última hora cuando se acercan las fechas de entrega de los proyectos y en entornos productivos</li>
            <li>Disponibilidad constante de una versión para pruebas o lanzamientos anticipados</li>
            <li>Ejecución inmediata de las pruebas unitarias</li>
            <li>Monitorización continua de las métricas de calidad del proyecto</li>
          </ul>
        </div>
      </div>
      <div className="services-main gray">
        <div className="services-main-content">
          <h2>Integración continua, una práctica de DevOps</h2><hr></hr>
          <p>En esta práctica se combinan herramientas de gestión de configuración (CM) con otras herramientas de pruebas y desarrollo para detectar qué cantidad del código que está en proceso de creación está listo para pasar a producción, así como herramientas de control de despliegue. Para ello, es importante mantener un intercambio fluido de información entre las fases de prueba y de desarrollo que permita identificar y resolver con rapidez los posibles problemas que puedan surgir en el código.</p>
        </div>
      </div>
      <div className="services-main">
        <div className="services-main-content">
          <h2>Jenkins, una herramienta de integración continua</h2><hr></hr>
          <p>Jenkins es un servidor de automatización open source que se utiliza para compilar y probar proyectos de software de forma continua, lo que facilita a los desarrolladores integrar cambios en un proyecto y entregar nuevas versiones a los usuarios.</p><br/>
          <h3>¿Qué se puede hacer con Jenkins?</h3>
          <p>Con esta herramienta podemos automatizar varias tareas que nos ayudarán a reducir el time-to-market de nuestros productos digitales o de las nuevas versiones de los mismos. Concretamente, con Jenkins podemos:</p>
          <ul>
            <li>Automatizar la compilación y testeo de software</li>
            <li>Notificar a los equipos correspondientes la detección de errores</li>
            <li>Desplegar los cambios en el código que hayan sido validados</li>
            <li>Hacer un seguimiento de la calidad del código y de la cobertura de las pruebas</li>
          </ul>
        </div>
      </div>
      <div className="services-main gray">
        <div className="services-main-content">
          <h2>¿En qué te puede ayudar Qualoom?</h2><hr></hr>
          <p>A través de la utilización de herramientas que posibiliten la implementación de esta metodología de trabajo entre departamentos de TI, producto, desarrollo y negocio, nuestro equipo de ingeniería pone a disposición conocimiento y experiencia para mejorar la calidad de los productos generados y reducir los costes productivos de manera continuada.</p>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Integracion
